// ======= Cookie message =======

window.addEventListener('load', function(){
	jQuery('#cookie-message-close').click(function(){
		jQuery('#cookie-message').hide();
	});

	if (!Cookies.get("cookie-message-bar"))
	{
	  jQuery('#cookie-message').show();
	  Cookies.set('cookie-message-bar', true, { expires: 120 }); // Cookie expiration (days)
	}
});
